
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { GridTable } from '@/mixins/grid-table';
import { auth } from '@/config/lib';

@Component({})
export default class Notifications extends Mixins<GridTable>(GridTable) {
  path = 'notifications';
  filters = {
    rows: 10,
    page: 1,
    userId: auth.get().user._id,
    receiverId: auth.get().user.role._id,
  };
  async mounted() {
    await this.listItems();
  }
}
